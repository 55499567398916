import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams
} from "react-router-dom";
import "./styles.css";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getFirestore,
  collection,
  doc,
  addDoc,
  getDocs,
  getDoc,
  query,
  where
} from "firebase/firestore";
import { getPerformance } from "firebase/performance";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyC36fyiqTKKqFJ4Qrzm2poX6f4A9JAzlS8",
  authDomain: "webstore-216d8.firebaseapp.com",
  projectId: "webstore-216d8",
  storageBucket: "webstore-216d8.appspot.com",
  messagingSenderId: "179436993574",
  appId: "1:179436993574:web:b649d337d7274ab90b7ae7",
  measurementId: "G-NRP4JFHCC9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
// Initialize Performance Monitoring and get a reference to the service
const perf = getPerformance(app);
const auth = getAuth();
const provider = new GoogleAuthProvider();
const db = getFirestore();
const storage = getStorage();

let global = {};
global.user = {};

async function post(collectionName, data) {
  try {
    const docRef = await addDoc(collection(db, collectionName), data);
    console.log("Document written with ID: ", docRef.id);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}

async function get() {
  const querySnapshot = await getDocs(collection(db, "users"));
  querySnapshot.forEach((doc) => {
    console.log(`${doc.id} => ${doc.data()}`);
  });
}

async function getTopicData(id) {
  const docRef = doc(db, "pages", id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    //console.log("Document data:", docSnap.data());
    let err = false;
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
    let err = true;
  }

  return docSnap.data();
}

getDownloadURL(ref(storage, "images/stars.jpg"))
  .then((url) => {
    // `url` is the download URL for 'images/stars.jpg'

    // This can be downloaded directly:
    const xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = (event) => {
      const blob = xhr.response;
    };
    xhr.open("GET", url);
    xhr.send();

    // Or inserted into an <img> element
    const img = document.getElementById("myimg");
    img.setAttribute("src", url);
  })
  .catch((error) => {
    // Handle any errors
  });

export default function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/signin">
            <Signin />
          </Route>
          <Route path="/pages">
            <Pages />
          </Route>
          <Route path="/adminPanel">
            <AdminPanel />
          </Route>
          <Route path="/addpage">
            <Addpage />
          </Route>
          <Route path="/viewpages">
            <Viewpages />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = { imgSrc: "", id: "HwzrzYVfUAOsMnxQO0uO", images: [] };
    this.getimage();
  }

  getimage = async () => {
    const docRef = doc(db, "pages", this.state.id);
    const docSnap = await getDoc(docRef);
    let data = {};

    if (docSnap.exists()) {
      //console.log("Document data:", docSnap.data());
      data = docSnap.data();
      this.state.images = data.images;
      this.getImage();
    }
  };

  async getImage() {
    getDownloadURL(
      ref(storage, this.state.id + "/" + this.state.images[0] + ".png")
    )
      .then((url) => {
        // `url` is the download URL for 'images/stars.jpg'

        console.log(url);
        // Or inserted into an <img> element
        const img = document.getElementById("mainImage");
        img.setAttribute("src", url);
      })
      .catch((error) => {
        // Handle any errors
        console.log("err", error);
      });
  }

  render() {
    return (
      <div>
        <NavBar />
        <h2>Featured</h2>
        <img id="mainImage" alt="" src="" height="200px" width="300"></img>
      </div>
    );
  }
}

function Signin() {
  return (
    <div>
      <NavBar />
      <MyForm />
    </div>
  );
}

function Pages() {
  let match = useRouteMatch();
  //let { Id } = useParams();
  return (
    <div>
      <NavBar />

      <Switch>
        <Route path={`${match.path}/:Id`}>
          <Page />
        </Route>
        <Route path={match.path}>
          <h3>Pages Home</h3>
        </Route>
      </Switch>
    </div>
  );
}

function Page() {
  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.
  let { Id } = useParams();
  return <FullPage id={Id} />;
}

function NavBar() {
  return (
    <div className="navbar">
      <SearchBar />
      <div className="dropdown">
        <button className="dropbtn">Menu</button>
        <div className="dropdown-content">
          <Link id="button" className="navbar" to="/">
            Home
          </Link>
          <Link id="button" className="navbar" to="/signin">
            Signin
          </Link>
          <Link id="button" className="navbar" to="/pages">
            Pages
          </Link>
          <AdminLinks />
        </div>
      </div>
    </div>
  );
}

function AdminLinks() {
  if (global.user.uid == "C0JyQHSBLEZ6rYVEulZKwD738LA2") {
    //console.log("admin@admin.com");
    return (
      <Link id="button" className="navbar" to="/adminPanel">
        adminPanel
      </Link>
    );
  } else {
    return null;
  }
}

class FullPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = { name: "", description: "", id: props.id, images: [] };

    this.fetchUserEmail(props.id).then((data) => {
      //console.log(data);
      this.setState({
        name: data.name,
        description: data.description,
        images: data.images
      });
      this.getImage();
    });
  }

  update(data) {}

  async getImage() {
    getDownloadURL(
      ref(storage, this.state.id + "/" + this.state.images[0] + ".png")
    )
      .then((url) => {
        // `url` is the download URL for 'images/stars.jpg'

        console.log(url);
        // Or inserted into an <img> element
        const img = document.getElementById("myimg");
        img.setAttribute("src", url);
      })
      .catch((error) => {
        // Handle any errors
        console.log("err", error);
      });
  }

  async fetchUserEmail(id) {
    const docRef = doc(db, "pages", id);
    const docSnap = await getDoc(docRef);
    let data = {};

    if (docSnap.exists()) {
      //console.log("Document data:", docSnap.data());
      data = docSnap.data();

      let err = false;
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
      let err = true;
    }

    return data;
  }

  render() {
    return (
      <div>
        <h1>{this.state.name}</h1>
        <h1>{this.state.images[0]}</h1>
        <img id="myimg" alt="error" width="136.6" height="76.8"></img>
      </div>
    );
  }
}
class MyForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { email: "", password: "" };
  }
  googleSignin = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        global.user = user;
        console.log(user);
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.log(errorCode);
        console.log(errorMessage);
        // ...
      });
  };
  emailSignin = (event) => {
    event.preventDefault();

    signInWithEmailAndPassword(auth, this.state.email, this.state.password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log(user);
        global.user = user;
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode);
        console.log(errorMessage);
        // ..
      });
  };
  emailChange = (event) => {
    this.setState({ email: event.target.value });
  };
  passwordChange = (event) => {
    this.setState({ password: event.target.value });
  };
  render() {
    return (
      <div id="signindiv">
        <form onSubmit={this.emailSignin} className="center">
          <p className="tinytextleft">email:</p>
          <input className="inputbox" type="text" onChange={this.emailChange} />
          <p className="tinytextleft">password:</p>
          <input
            className="inputbox"
            type="text"
            onChange={this.passwordChange}
          />
          <br />
          <input type="submit" />
        </form>
        <br />
        <hr />
        <button onClick={this.googleSignin}>Sign In With Google</button>
      </div>
    );
  }
}

class AdminPanel extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <Link to="addpage">Add Page</Link>
        <br />
        <Link to="viewpages">View All Pages</Link>
      </div>
    );
  }
}

class Viewpages extends React.Component {
  constructor(props) {
    super(props);
    this.state = { id: [], data: [], links: [] };
    this.getall().then((id) => {
      this.createlist();
    });
  }

  getall = async () => {
    const pages = collection(db, "pages");

    // Create a query against the collection.
    const q = query(pages, where("live", "==", true));
    const querySnapshot = await getDocs(q);

    let id = [];
    let data = [];

    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      //console.log(doc.id, " => ", doc.data());
      id.push(doc.id);
      data.push(doc.data());
    });
    this.setState({ id: id, data: data });
  };

  createlist = () => {
    //console.log(this.state, "i");
    //console.log(this.state.id.length);
    for (let i = 0; i < this.state.id.length; i++) {
      let item = this.state.data[i].name;
      let to = "pages/" + this.state.id[i];
      this.state.links.push(
        <div>
          <Link key={i} to={to}>
            {item}
          </Link>
        </div>
      );
    }

    this.setState({});
  };

  render() {
    return <div>{this.state.links}</div>;
  }
}
class Addpage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      developer: "",
      version: "",
      description: "",
      creationDate: "",
      DateType: "",
      links: [],
      //images blanks equal image in src for no image ex: ./blank.png
      images: ["", "", "", ""],
      live: true
    };
  }

  nameChange = (event) => {
    this.setState({ name: event.target.value });
  };
  developerChange = (event) => {
    this.setState({ developer: event.target.value });
  };
  versionChange = (event) => {
    this.setState({ version: event.target.value });
  };
  descriptionChange = (event) => {
    this.setState({ description: event.target.value });
  };
  linkpush = (event) => {
    this.state.links.push(event.target.value);
  };
  creationDateChange = (event) => {
    this.setState({ creationDate: event.target.value });
  };
  DateTypeChange = (event) => {
    this.setState({ DateType: event.target.value });
  };

  pageCreate = async () => {
    console.log(this.state);
    await addDoc(collection(db, "pages"), this.state)
      .then((err) => {
        console.log("addDoc DOne");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    return (
      <div id="addpage">
        <NavBar />
        <form>
          <p className="tinytextleft">name:</p>
          <input type="text" onChange={this.nameChange} />
          <p className="tinytextleft">developer:</p>
          <input type="text" onChange={this.developerChange} />
          <p className="tinytextleft">version:</p>
          <input type="text" onChange={this.versionChange} />
          <p className="tinytextleft">description:</p>
          <textarea onChange={this.descriptionChange} />
          <p className="tinytextleft">links:</p>
          <input type="text" onChange={this.linkpush} />
          <br />
          <input type="text" onChange={this.linkpush} />
          <br />
          <input type="text" onChange={this.linkpush} />
          <br />
          <input type="text" onChange={this.linkpush} />
          <br />
          <p className="tinytextleft">creationDate:</p>
          <input type="date" onChange={this.creationDateChange} />
          <p className="tinytextleft">DateType:</p>
          <input type="text" onChange={this.DateTypeChange} />
          <br />
          <button type="button" onClick={this.pageCreate}>
            Submit
          </button>
        </form>
      </div>
    );
  }
}

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
  }

  updateSeach = () => {
    console.log("u");
  };

  seach = () => {
    console.log("s");
  };

  render() {
    return (
      //add div top padding
      <div>
        <input
          type="text"
          className="navbar"
          placeholder="Search.."
          onChange={this.updateSeach}
          onSubmit={this.seach}
        ></input>
      </div>
    );
  }
}
